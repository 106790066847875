<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <b-card @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="KnowledgeAddForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(updateKnowledge)"
            @reset.prevent="resetForm()"
          >
            <b-row class="align-items-end">
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="knowledge.code"
                  :disabled="!isAllowedEdit"
                  :rules="{ required: false }"
                  name="Code"
                  label="Code"
                  placeholder="Input Code"
                  trim
                />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="knowledge.subject"
                  :disabled="!isAllowedEdit"
                  :rules="{ required: true }"
                  name="Subject"
                  label="Subject"
                  placeholder="Input Subject"
                  trim
                />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <SelectField
                  v-model="knowledge.knowledgeTypeId"
                  :disabled="!isAllowedEdit"
                  :options="knowledgeType"
                  name="knowledgeType"
                  form-label="Knowledge Type"
                  label-option="title"
                  placeholder="Knowledge Type"
                  :rules="{
                    required: true,
                  }"
                  return-value="id" />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TagsField
                  v-model="knowledge.tags"
                  multiple
                  name="Tags"
                  :disabled="!isAllowedEdit"
                  form-label="Tags"
                  label="Tags"
                />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group label="Problem Detail">
                  <!-- <quill-editor
                    v-model="knowledge.problemDetail"
                    :disabled="!isAllowedEdit"
                    :rules="{ required: true }"
                    name="Problem Detail"
                    label="Problem Detail"
                    placeholder="Input Problem Detail"
                    trim
                  /> -->
                  <div
                    v-if="$checkRole(addKnowledgePermission, profileData.role)"
                    class="my-2">
                    <PreviewSwitch
                      v-model="isProblemDetailPreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isProblemDetailPreview || !$checkRole(addKnowledgePermission, profileData.role)"
                    class="box-preview"
                    v-html="knowledge.problemDetail"
                  >
                  </div>
                  <wysiwyg
                    v-else
                    v-model="knowledge.problemDetail"
                  />
                </b-form-group>
                <b-button
                  v-if="$checkRole(addKnowledgePermission, profileData.role)"
                  v-ripple.400="'rgba(94,88,115)'"
                  :disabled="!isAllowedEdit"
                  style="background: #5e5873 !important"
                  class="mt-2"
                  type="button"
                  @click="openFile('problemFile')"
                >
                  Attach Problem Detail
                </b-button>
                <input
                  ref="addProblemFile"
                  :disabled="!isAllowedEdit"
                  class="d-none"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
                  multiple
                  @change="onChangeFile($event, 'problemFile')" />
                <p
                  v-show="isAllowedEdit"
                  class="mt-2 text-danger">
                  สามารถอัปโหลดได้ไม่เกิน 25MB
                </p>
              </b-col>
              <b-col
                class="my-2"
                cols="12">
                <ul>
                  <li
                    v-for="(item, index) in problemFile"
                    :key="index"
                    class="my-2"
                  >
                    <span class="relative">
                      <label
                        v-show="isAllowedEdit"
                        class="remove-file">
                        <span
                          class="pointer"
                          @click="removeFile(index, 'problemFile', `${item && item.file ? 'files' : 'problemFile'}`)">
                          X
                        </span>
                      </label>
                      <a
                        :href="item.url ? item.url : ''"
                        target="_blank">{{ item && item.name ? item.name : '' }}</a>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group label="Solution Detail">
                  <!-- <quill-editor
                    v-model="knowledge.solutionDetail"
                    :disabled="!isAllowedEdit"
                    :rules="{ required: true }"
                    name="Solution Detail"
                    label="Solution Detail"
                    placeholder="Input Solution Detail"
                    trim
                  /> -->
                  <div
                    v-if="$checkRole(addKnowledgePermission, profileData.role)"
                    class="my-2"
                  >
                    <PreviewSwitch
                      v-model="isSolutionDetailPreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isSolutionDetailPreview || !$checkRole(addKnowledgePermission, profileData.role)"
                    class="box-preview"
                    v-html="knowledge.solutionDetail"
                  >
                  </div>
                  <wysiwyg
                    v-else
                    v-model="knowledge.solutionDetail"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-if="$checkRole(addKnowledgePermission, profileData.role)"
              v-ripple.400="'rgba(94,88,115)'"
              style="background: #5e5873 !important"
              class="mt-2"
              type="button"
              :disabled="!isAllowedEdit"
              @click="openFile()"
            >
              Attach Solution Detail
            </b-button>
            <input
              ref="addFiles"
              class="d-none"
              type="file"
              :disabled="!isAllowedEdit"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
              multiple
              @change="onChangeFile($event, 'solutionFile')" />
            <p
              v-show="isAllowedEdit"
              class="mt-2 text-danger">
              สามารถอัปโหลดได้ไม่เกิน 25MB
            </p>
            <b-row>
              <b-col
                class="my-2"
                cols="12">
                <ul>
                  <li
                    v-for="(item, index) in solutionFile"
                    :key="index"
                    class="my-2"
                  >
                    <span class="relative">
                      <label
                        v-show="isAllowedEdit"
                        class="remove-file">
                        <span
                          class="pointer"
                          @click="removeFile(index, 'solutionFile', `${item && item.file ? 'files' : 'solutionFile'}`)">
                          X
                        </span>
                      </label>
                      <a
                        :href="item.url ? item.url : ''"
                        target="_blank">{{ item && item.name ? item.name : '' }}</a>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
            <div
              v-if="$checkRole(addKnowledgePermission, profileData.role)"
              class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!isAllowedEdit"
                variant="danger"
                class="mt-5 mr-2 btn-submit"
                @click="openDeleteModal()"
              >
                Delete
              </b-button>
            </div>
            <div
              v-if="$checkRole(addKnowledgePermission, profileData.role)"
              class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!isAllowedEdit"
                variant="primary"
                class="mt-5 mr-2 btn-submit"
                type="submit"
              >
                Save Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="!isAllowedEdit"
                type="button"
                class="mt-5 mr-5 btn-submit"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
import PreviewSwitch from '@/components/PreviewSwitch.vue'
import TagsField from '@/components/Form/TagsField.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import UploadService from '@/resources/UploadProvider'
import DeleteModal from '@/components/Modal/DeleteModal.vue'
import AddKnowledgePermission from '@/common/permission/knowledge/add-knowledge'
import KnowledgeTypeProvider from '@/resources/KnowledgeTypeProvider'

const CategoriesService = new CategoriesProvider()
const KnowledgeService = new KnowledgeProvider()
const KnowledgeTypeService = new KnowledgeTypeProvider()

export default {
  components: {
    DeleteModal,
    TextFieldInput,
    SelectField,
    TagsField,
    PreviewSwitch
  },
  data () {
    return {
      isDeleteModalActive: false,
      isProblemDetailPreview: false,
      isSolutionDetailPreview: false,
      deleteItem: {},
      knowledgeType: [],
      knowledge: {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: '',
        solutionFile: '',
        knowledgeTypeId: null,
        tags: []
      },
      mockData: [
        { label: '#001', value: 0 },
        { label: '#002', value: 1 },
        { label: '#003', value: 2 },
        { label: '#004', value: 3 },
        { label: '#005', value: 4 }
      ],
      files: {
        problemFile: [],
        solutionFile: []
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      categories: [],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      addKnowledgePermission: AddKnowledgePermission
    }
  },
  computed: {
    problemFile () {
      const result = []
      if (this.knowledge.problemFile.length > 0) {
        result.push(...this.knowledge.problemFile)
      }
      if (this.files.problemFile.length > 0) {
        result.push(...this.files.problemFile)
      }
      return result
    },
    solutionFile () {
      const result = []
      if (this.knowledge.solutionFile.length > 0) {
        result.push(...this.knowledge.solutionFile)
      }
      if (this.files.solutionFile.length > 0) {
        result.push(...this.files.solutionFile)
      }
      return result
    },
    isAllowedEdit () {
      const allowedRoles = ['super admin', 'supervisor', 'manager', 'management']
      return (
        allowedRoles.includes(this.profileData?.role)
      )
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profileData: 'User/user',
    })
  },
  async mounted () {
    await this.getAllCategories()
    await this.getKnowledge()
    this.getKnowledgeType()
  },
  methods: {
    async getAllCategories () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CategoriesService.findAll()
        this.categories = data
        this.lvlOneOptions = data.filter((item) => item.level === 1)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getKnowledge () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await KnowledgeService.findOne(this.$route.params.id)
        this.knowledge = data
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getKnowledgeType () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await KnowledgeTypeService.paginate(1, 999999)
        if (data) {
          this.knowledgeType = data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async updateKnowledge () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const [problemFile, solutionFile] = await Promise.all([
          this.uploadFile(this.problemFile),
          this.uploadFile(this.solutionFile)
        ])

        const payload = {
          code: this.knowledge.code,
          subject: this.knowledge.subject,
          problemDetail: this.knowledge.problemDetail,
          solutionDetail: this.knowledge.solutionDetail,
          knowledgeTypeId: this.knowledge.knowledgeTypeId
        }

        if (Array.isArray(problemFile) && problemFile.length > 0) {
          payload.problemFile = JSON.stringify(problemFile)
        } else {
          payload.problemFile = '[]'
        }

        if (Array.isArray(solutionFile) && solutionFile.length > 0) {
          payload.solutionFile = JSON.stringify(solutionFile)
        } else {
          payload.solutionFile = '[]'
        }

        await KnowledgeService.updateGroup(this.knowledge.id, payload)
        this.showToast('success', 'Updated Knowledge Successfully')
        this.resetForm()
        this.getKnowledge()
      } catch (err) {
        this.showToast('danger', `Knowledge Update Failed: ${err}`)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async confirmDelete (val) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        this.isSidebarDetailActive = false
        await KnowledgeService.deleteGroup(val.ID)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: `👋 Knowledge ${this.deleteItem.Name} has been deleted`
          }
        })
        this.$router.push('/knowledge')
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlTwoOptions = filter
        this.lvlThreeOptions = []
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlTwo = null
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 2:
        this.lvlThreeOptions = filter
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 3:
        this.lvlFourOptions = filter
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 4:
        this.lvlFiveOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 5:
        this.lvlSixOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlSix = null
        break
      default:
        break
      }
    },
    findCategory (parentId) {
      return this.categories.filter((item) => item.parentId === parentId)
    },
    filterParentCategory () {
      const filter = this.categories.filter((item) => item?.id === this.knowledge.categoryId)
      const result = [...filter]
      for (let i = 0; i < filter[0].level; i++) {
        const fil = this.categories.filter((item) => item?.id === result[i]?.parentId)
        result.push(...fil)
        const filters = this.findCategory(result[i]?.parentId)
        switch (result[i].level) {
        case 1:
          this.lvlOneOptions = filters
          break
        case 2:
          this.lvlTwoOptions = filters
          break
        case 3:
          this.lvlThreeOptions = filters
          break
        case 4:
          this.lvlFourOptions = filters
          break
        case 5:
          this.lvlFiveOptions = filters
          break
        case 6:
          this.lvlSixOptions = filters
          break
        default:
          break
        }
      }
      result.forEach((item) => {
        switch (item?.level) {
        case 1:
          this.categoryLevel.categorySearchLvlOne = item.id
          break
        case 2:
          this.categoryLevel.categorySearchLvlTwo = item.id
          break
        case 3:
          this.categoryLevel.categorySearchLvlThree = item.id
          break
        case 4:
          this.categoryLevel.categorySearchLvlFour = item.id
          break
        case 5:
          this.categoryLevel.categorySearchLvlFive = item.id
          break
        case 6:
          this.categoryLevel.categorySearchLvlSix = item.id
          break
        default:
          break
        }
      })
    },
    getLastItem (obj) {
      const convertToArray = Object.keys(obj).map((key) => obj[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    },
    resetForm () {
      this.knowledge = {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: [],
        solutionFile: [],
        categoryId: ''
      }
      this.files = {
        problemFile: [],
        solutionFile: []
      }
      this.lvlTwoOptions = []
      this.lvlThreeOptions = []
      this.lvlFourOptions = []
      this.lvlFiveOptions = []
      this.lvlSixOptions = []
      this.categoryLevel = {
        categorySearchLvlOn: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      }
      this.$refs.KnowledgeAddForm.reset()
    },
    openDeleteModal () {
      this.deleteItem = { ID: this.knowledge.id }
      this.isDeleteModalActive = true
    },
    openFile (val) {
      if (val === 'problemFile') {
        this.$refs.addProblemFile.click()
      } else {
        this.$refs.addFiles.click()
      }
    },
    async uploadFile (files) {
      const arr = []
      try {
        if (files && files.length > 0 && typeof files === 'object') {
          for (const item of files) {
            const formData = new FormData()
            formData.append('file', item.file)
            // eslint-disable-next-line no-await-in-loop
            const { data: { data } } = await UploadService.upload(formData)
            arr.push({ name: item.name, url: data?.publicUrl || item?.url })
          }
        }
        return arr
      } catch (error) {
        console.error(error)
        return arr
      }
    },
    onChangeFile (event, key) {
      const { files } = event.target
      let errorMaximumSize = false
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < 26214400) {
          this.files[key].push({
            file: files[i],
            name: files[i].name,
            url: URL.createObjectURL(files[i])
          })
        } else {
          errorMaximumSize = true
        }
      }
      if (errorMaximumSize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error: File size exceeds the maximum limit',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'maximum size of 10mb.'
          }
        })
      }
    },
    removeFile (index, key, type) {
      if (type === 'problemFile') {
        this.knowledge.problemFile.splice(index, 1)
      }
      if (type === 'solutionFile') {
        this.knowledge.solutionFile.splice(index, 1)
      }
      if (type === 'files') {
        this.files[key].splice(index, 1)
      }
    },
    showToast (variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'EditIcon',
          variant,
          text: `👋 ${text}`
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .ql-container.ql-snow > .ql-editor {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.remove-file {
  position: absolute;
  right: -17px;
  bottom: 11px;
}
</style>
